import PropTypes from 'prop-types';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function TypeText({ children }) {
  const letters = children.split('');
  const container = {
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: i => ({
      opacity: 1,
      transition: {
        delay: i * 0.05,
      },
    }),
  };

  return (
    <AnimatePresence>
      <motion.span variants={container} initial="hidden" animate="show" exit="hidden">
        {letters.map((letter, index) => {
          return (
            <motion.span key={letters + '-' + letter + '-' + index} custom={index} variants={item}>
              {letter}
            </motion.span>
          );
        })}
      </motion.span>
    </AnimatePresence>
  );
}

TypeText.propTypes = {
  children: PropTypes.string.isRequired,
};

export default TypeText;
