export default function useCtaTextQuery() {
  const data = [
    'Are you looking for Virtual CXOs? (CIO, CHRO, CFO)',
    'Are you looking for Legal Advice?',
    'Do you need Advisors to the Board in areas like HR, Strategy, Sales?',
    'Interested in flexy hire?',
    'Do you need Advisors to the Board in areas like Direct Selling, Finance or Risk Management?',
    'Would you like to implement TSE or TSM or Six Sigma?',
    'Are you thinking of F&A shared services or need to help to augment your team of accountants?',
    'Would you like to implement frameworks like IFC or ERM but need help from folks who have done it in real life?',
    'Do you need help in smarter and effective communication or CSR or Public Relations?',
  ];
  return data;
}
