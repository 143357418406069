import React from 'react';
import Input from '../input';

function ContactSection() {
  return (
    <div className="py-10 text-gray-900 bg-blue-100 md:py-20">
      <div className="max-w-screen-md px-4 mx-auto">
        <h2 className="mb-1 text-2xl font-bold md:text-3xl lg:text-4xl">Contact 2nd Innings</h2>
        <p className="p-1 leading-normal text-gray-700">
          For further queries regarding 2nd Innings Advisory you can fill out this form and we will get in touch with
          you.
        </p>
        <div className="mt-10">
          <form
            name="contact-query"
            action="/thankyou/"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact-query" />

            <div className="mb-4 sm:flex">
              <Input name="name" placeholder="Enter your name" label="Name" />
              <Input name="email" placeholder="your.email@example.com" label="Email" type="email" />
            </div>
            <div className="mb-4 sm:flex">
              <Input name="mobile" placeholder="9912345678" label="Mobile Number" type="tel" pattern="^\d{10}$" />
              <Input name="subject" placeholder="Query" label="Subject" type="text" />
            </div>

            <div className="mb-4">
              <label className="block">
                <span className="text-gray-700">Message</span>
                <textarea
                  className="block w-full mt-1 form-textarea"
                  rows="12"
                  name="message"
                  placeholder="Enter your message"
                  required></textarea>
              </label>
            </div>
            <div className="mt-8">
              <button className="px-6 py-4 font-bold text-white capitalize rounded bg-suite-blue" type="submit">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
