import { useStaticQuery, graphql } from 'gatsby';

export default function useServicesSectionQuery() {
  const data = useStaticQuery(graphql`
    query {
      items: allServicesListYaml(sort: { fields: file_name }) {
        edges {
          node {
            id
            title
            icon
            description
          }
        }
      }
    }
  `);
  return data.items.edges.map(({ node }) => node);
}
