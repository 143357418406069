import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import useSelectPartnerImage from '../../hooks/useSelectPartnerImage';
import ArrowRight from '../../svgs/arrow-right.svg';

function PartnersList(props) {
  if (props.partners.length === 0) {
    return null;
  }
  return props.partners.edges.map(({ node }) => (
    <div
      key={node.id}
      className="px-4 py-3 my-8 border-t border-b border-gray-300 sm:w-1/2 sm:border-0 sm:my-0 sm:px-4 lg:w-1/3">
      <Link to={`/${node.name}`} className="flex p-4 shadow sm:bg-gray-100 sm:rounded-md hover:shadow-lg">
        <Img fixed={props.selectImage(node.name).fixed} alt={node.info.name} />
        <span className="flex flex-col justify-center ml-3">
          <h4 className="text-lg font-bold text-gray-800">{node.info.name}</h4>
          <h5 className="text-sm font-bold text-gray-700 lg:text-xs">{node.info.experience.industry}</h5>
          <span className="flex items-center text-sm font-bold text-blue-700">
            View Profile <ArrowRight className="w-6 h-3 ml-1" />
          </span>
        </span>
      </Link>
    </div>
  ));
}

function Partners() {
  const data = useStaticQuery(graphql`
    query PartnerSectionQuery {
      partners: allFile(filter: { sourceInstanceName: { eq: "members" }, name: { ne: "template" } }) {
        edges {
          node {
            id
            name
            info: childMembersYaml {
              name
              experience {
                industry
              }
            }
          }
        }
      }
    }
  `);

  const selectImage = useSelectPartnerImage(data.images, data.placeholderImage);

  // Don't show if no partners
  if (data.partners.edges.length <= 0) {
    return null;
  }

  return (
    <section className="bg-white">
      <div className="py-5 mx-auto max-w-screen-2xl md:py-10 lg:py-20 xl:py-30">
        <h2 className="mb-1 text-2xl font-bold text-center capitalize">Partners</h2>
        <div className="flex-wrap max-w-screen-xl py-4 mx-auto sm:flex">
          <PartnersList partners={data.partners} selectImage={selectImage} />
        </div>
      </div>
    </section>
  );
}

export default Partners;
