import { useStaticQuery, graphql } from 'gatsby';

export default function useWhyChooseSectionQuery() {
  const data = useStaticQuery(graphql`
    query {
      items: allWhyChooseYaml(sort: { fields: file_name }) {
        edges {
          node {
            id
            title
            description
          }
        }
      }
    }
  `);
  return data.items.edges.map(({ node }) => node);
}
