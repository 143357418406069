import { useState, useEffect } from 'react';

export default function useCycleText(listText = [], s = 1) {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const timmer = window.setInterval(
      () =>
        setCurrent(prev => {
          if (prev === listText.length - 1) {
            return 0;
          }

          return prev + 1;
        }),
      s * 1000,
    );
    return () => {
      window.clearInterval(timmer);
    };
  }, [s, listText]);

  return listText[current];
}
