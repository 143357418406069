import React from 'react';
import useAboutQuery from '../../hooks/useAboutQuery';

function AboutSection() {
  const item = useAboutQuery('home');

  return (
    <section className="px-4 py-10 bg-white sm:px-10 lg:py-20">
      <div className="max-w-screen-md mx-auto">
        <h2 className="mb-5 text-2xl font-bold text-center text-gray-800">{item.title}</h2>
        <p className="my-4 leading-relaxed text-gray-700">{item.content}</p>
      </div>
    </section>
  );
}

export default AboutSection;
