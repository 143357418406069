import { useStaticQuery, graphql } from 'gatsby';

export default function useAboutQuery(sectionName) {
  const data = useStaticQuery(graphql`
    query {
      allAboutYaml {
        edges {
          node {
            section
            title
            content
          }
        }
      }
    }
  `);

  const item = data.allAboutYaml.edges.find(({ node }) => node.section === sectionName);

  return item.node;
}
