import React from 'react';
import LogoWhiteImage from './images/logo-white-image';

function Footer() {
  return (
    <footer className="bg-gray-800">
      <div className="p-10 mx-auto text-gray-500 max-w-screen-2xl">
        <div className="flex flex-col items-start justify-between mt-6 md:flex-row md:items-end md:pb-16">
          <span className="mb-6 text-xs md:mb-0">&copy; RJC 2nd Innings</span>
          <LogoWhiteImage />
        </div>
      </div>
      <div className="py-6 text-sm text-center text-gray-600 hover:text-gray-300">
        <p>
          Website designed and developed by{' '}
          <a href="https://www.eshaanmathur.com/" className="text-gray-300 underline hover:text-gray-100">
            Eshaan Mathur
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
