import { useStaticQuery, graphql } from 'gatsby';

export default function useSelectPartnerImage() {
  const data = useStaticQuery(graphql`
    query PartnersImageQuery {
      placeholder: file(sourceInstanceName: { eq: "images" }, name: { eq: "placeholder-partner" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      images: allFile(filter: { relativePath: { glob: "partners/*" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(width: 80) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);
  const selectImage = name => {
    const foundImage = data.images.edges.find(({ node }) => node.name === name);
    if (foundImage) return foundImage.node.childImageSharp;
    return data.placeholder.childImageSharp;
  };

  return selectImage;
}
