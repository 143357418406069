import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import LogoImage from './images/logo-image';
import useScrollButtonState from '../hooks/useScrollButtonState';
import { Waypoint } from 'react-waypoint';
import LinkedInIcon from '../svgs/linkedin.svg';

function Header({ siteTitle }) {
  const { hide } = useScrollButtonState();
  return (
    <header className="bg-blue-100">
      <Waypoint onEnter={hide} />
      <div className="flex items-center justify-between p-2 mx-auto max-w-screen-2xl lg:px-16">
        <Link to="/" title={siteTitle}>
          <LogoImage />
        </Link>
        <span className="flex items-center text-sm text-gray-700">
          <span className="hidden sm:inline">Follow us on our</span>{' '}
          <a
            href="https://www.linkedin.com/company/rjc-2nd-innings-advisory"
            className="flex items-center px-4 py-2 ml-1 text-gray-100 bg-blue-500 rounded"
            aria-label="Follow us on linked in"
            title="Follow us on our linked in page">
            <LinkedInIcon className="inline w-4 h-4 mr-1" /> LinkedIn Page
          </a>
        </span>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
