import PropTypes from 'prop-types';
import React from 'react';

function Input(props) {
  if (props.pattern !== '') {
    return (
      <label className="block mb-2 sm:w-1/2 sm:pr-1">
        <span className="ml-2 text-gray-700">{props.label}</span>
        <input
          className="block w-full mt-1 form-input"
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          pattern={props.pattern}
          required
        />
      </label>
    );
  }
  return (
    <label className="block mb-2 sm:w-1/2 sm:pr-1">
      <span className="ml-2 text-gray-700">{props.label}</span>
      <input
        className="block w-full mt-1 form-input"
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        required
      />
    </label>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  pattern: PropTypes.any,
};

Input.defaultProps = {
  type: 'text',
  pattern: '',
};

export default Input;
