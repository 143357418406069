import { useStaticQuery, graphql } from 'gatsby';

export default function useCompaniesLogoImages(compliesList = []) {
  const data = useStaticQuery(graphql`
    query CompaniesLogoImagesQuery {
      images: allFile(filter: { relativePath: { glob: "companies_logos/*" } }, sort: { fields: name }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(height: 80) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  return data.images.edges
    .filter(({ node }) => compliesList.includes(node.name))
    .map(({ node }) => ({ id: node.id, image: node.childImageSharp }));
}
