import React from 'react';
import useServicesSectionQuery from '../../hooks/useServicesSectionQuery';
import useWhyChooseSectionQuery from '../../hooks/useWhyChooseSectionQuery';
import Tick from '../../svgs/tick.svg';
import ServiceCard from '../service-card';

function MoreInfoSection() {
  const whyChooseItems = useWhyChooseSectionQuery();
  const services = useServicesSectionQuery();

  return (
    <section className="py-10 bg-blue-100">
      <div className="flex flex-col items-center py-20 mx-auto max-w-screen-2xl">
        <h2 className="text-2xl font-bold text-center capitalize">Why choose us</h2>
        <div className="grid row-gap-8 col-gap-4 px-4 mx-auto mt-10 sm:max-w-screen-xl lg:col-gap-8 sm:grid-cols-2 lg:grid-cols-5">
          {whyChooseItems.map(item => (
            <div className="relative px-4 pt-5 pb-4 bg-white rounded-md shadow" key={item.id}>
              <Tick className="absolute top-0 w-8 h-8 -mt-4 text-leaf-green" />
              <h4 className="mt-2 mb-1 font-bold text-gray-800 capitalize">{item.title}</h4>
              <p className="text-gray-700">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="py-10 bg-blue-100">
        <h2 className="mb-10 text-2xl font-bold text-center capitalize">Service Verticals</h2>
        <div className="max-w-screen-xl py-10 mx-auto sm:px-10 md:flex md:flex-wrap">
          {services.map(service => (
            <ServiceCard
              key={service.id}
              iconName={service.icon}
              title={service.title}
              description={service.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default MoreInfoSection;
