import React from 'react';
import SEO from '../components/seo';
import Cta from '../components/sections/cta';
import AboutSection from '../components/sections/about';
import MoreInfoSection from '../components/sections/more-info';
import Partners from '../components/sections/partners';
import ContactSection from '../components/sections/contact';
import useScrollButtonState from '../hooks/useScrollButtonState';
import { Waypoint } from 'react-waypoint';

function IndexPage() {
  const { show } = useScrollButtonState();
  return (
    <>
      <SEO title="Home" />
      <Cta />
      <AboutSection />
      <Waypoint onEnter={show} />
      <MoreInfoSection />
      <Partners />
      <ContactSection />
    </>
  );
}

export default IndexPage;
