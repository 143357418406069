import PropTypes from 'prop-types';
import React from 'react';
import Coaching from '../svgs/coach.svg';
import Advice from '../svgs/advice.svg';
import Project from '../svgs/project.svg';
import MenuIconSvg from '../svgs/menu.svg';
import CloseIconSvg from '../svgs/close.svg';

function Icon(props) {
  switch (props.name) {
    case 'coach':
      return <Coaching className={props.className} />;
    case 'advice':
      return <Advice className={props.className} />;
    case 'project':
      return <Project className={props.className} />;
    case 'close':
      return <MenuIconSvg className={props.className} />;
    case 'open':
      return <CloseIconSvg className={props.className} />;

    default:
      return null;
  }
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};

export default Icon;
