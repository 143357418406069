export default function useScrollTo(top = 0, left = 0) {
  const scroll = () => {
    const ua = (navigator?.userAgent).toLowerCase();
    const isSafari = ua.includes('safari') && !ua.includes('chrome');
    if (isSafari) {
      window.scrollTo(left, top);
    } else {
      window.scrollTo({ top, left, behavior: 'smooth' });
    }
  };
  return scroll;
}
