import React, { createContext, useState } from 'react';

export const ScrollButtonStateContext = createContext();

function ScrollButtonState(props) {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  return (
    <ScrollButtonStateContext.Provider value={{ visible, show, hide }}>
      {props.children}
    </ScrollButtonStateContext.Provider>
  );
}

export default ScrollButtonState;
