import React from 'react';
import useHeroImage from '../../hooks/useHeroImage';
import TypeText from '../type-text';
import useCycleText from '../../hooks/useCycleText';
import useCtaTextQuery from '../../hooks/useCtaTextQuery';

function Cta() {
  const hero = useHeroImage();
  const textList = useCtaTextQuery();
  const text = useCycleText(textList, 7);

  return (
    <section
      className="relative w-full bg-blue-800 bg-right-bottom bg-no-repeat bg-contain sm:h-560px md:h-720px"
      style={{
        backgroundImage: `url(${hero})`,
      }}>
      <div className="flex flex-col items-start justify-center h-full mx-auto sm:p-8 md:p-16 max-w-screen-2xl">
        <div className="flex flex-col items-start justify-center w-full p-8 shadow-lg min-h-64 lg:w-7/12 sm:rounded-md 2xl:shadow-none bg-overlay-white50 lg:bg-blue-100 2xl:bg-transparent">
          <h1 className="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl xl:mr-12 2xl:text-gray-100">
            <TypeText>{text}</TypeText>
          </h1>
          <h2 className="mt-1 text-lg text-gray-800 sm:text-2xl 2xl:text-gray-100 md:mt-4">
            Find an expert in our network
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Cta;
