import { useStaticQuery, graphql } from 'gatsby';

export default function useHeroImage() {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `);
  return data.hero.childImageSharp.original.src;
}
