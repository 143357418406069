import PropTypes from 'prop-types';
import React from 'react';
import SEO from '../components/seo';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import ArrowRight from '../svgs/arrow-right.svg';
import useCompaniesLogoImages from '../hooks/useCompaniesLogoImages';
import { Waypoint } from 'react-waypoint';
import useScrollButtonState from '../hooks/useScrollButtonState';

function Member(props) {
  const data = props.data.membersYaml;
  const image = props.data.image.childImageSharp;
  const companyLogos = useCompaniesLogoImages(data.companies);
  const files = data.files.filter(({ label }) => label !== 'none');
  const { show } = useScrollButtonState();

  return (
    <>
      <SEO title={data.name} />
      <article className="md:pt-16 md:bg-gray-800">
        <div className="flex flex-col items-center max-w-screen-sm px-4 py-10 mx-auto md:bg-gray-100 md:rounded-lg md:shadow-lg md:mb-16">
          <Img fixed={image.fixed} alt={data.name} />
          <div className="flex flex-col items-center px-8 py-2 text-gray-800">
            <h1 className="text-3xl font-bold leading-tight sm:text-4xl">{data.name}</h1>
            <span className="my-2 text-xs font-semibold text-center text-gray-600 uppercase">
              {data.tags.join(' | ')}
            </span>
            <a className="flex items-center font-bold text-gray-700 underline" href={`mailto:${data.email}`}>
              <svg className="w-6 h-6 mr-1" fill="currentColor" viewBox="0 0 20 20">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
              {data.email}
            </a>

            <div className="my-2 text-center text-gray-700">
              <h3 className="mb-1 text-base font-bold">{data.experience.industry}</h3>
              <p className="text-sm">
                <span className="font-bold">{data.experience.years}+</span> years experience.
              </p>
            </div>

            <ul className="flex -mx-2">
              {data.links.map(({ label, url }) => (
                <li key={label} className="mx-2">
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-col items-center text-blue-700 sm:flex-row sm:items-end">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
                    </svg>
                    {label}
                  </a>
                </li>
              ))}
            </ul>
            {files.length > 0 && (
              <ul className="flex mt-8 -mx-2">
                {files.map(({ label, file }) => (
                  <li key={label} className="mx-2">
                    <a href={file} target="_blank" rel="noopener noreferrer" className="flex items-end text-blue-700">
                      <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                          clip-rule="evenodd"
                          fill-rule="evenodd"></path>
                      </svg>
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="bg-white">
          <div className="px-4 py-5 md:py-10 lg:py-20">
            <h2 className="text-4xl font-bold text-center text-gray-800">Professional Offerings</h2>
            <div className="max-w-screen-md mx-auto my-8 text-lg">
              <ReactMarkdown source={data.offerings} escapeHtml={true} className="text-gray-700 markdown" />
            </div>
          </div>
        </div>
        <Waypoint onEnter={show} />
        <div className="bg-gray-100">
          <div className="px-4 py-5 md:py-10 lg:py-20">
            <h2 className="text-4xl font-bold text-center text-gray-800">Profile</h2>
            <div className="max-w-screen-md mx-auto my-8 text-lg">
              <ReactMarkdown source={data.profile} escapeHtml={true} className="text-gray-700 markdown" />
            </div>
          </div>
        </div>

        {companyLogos.length > 0 && (
          <div className="bg-white">
            <div className="p-10">
              <h2 className="font-semibold text-center text-gray-600 uppercase">Companies Associated With</h2>
              <div className="flex flex-wrap items-center justify-center max-w-screen-lg pt-1 pb-10 mx-auto">
                {companyLogos.map((logo) => (
                  <Img key={logo.id} fixed={logo.image.fixed} className="m-8" />
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center justify-center py-10 bg-white">
          <Link className="flex items-center px-5 py-2 text-white rounded bg-suite-blue" to="/">
            <ArrowRight className="w-6 h-3 mr-2 transform rotate-180" />
            Home Page
          </Link>
        </div>
      </article>
    </>
  );
}

Member.propTypes = {
  data: PropTypes.any,
};

export default Member;

export const query = graphql`
  query MemberQuery($id: String!, $image: String!) {
    membersYaml(id: { eq: $id }) {
      name
      tags
      email
      experience {
        years
        industry
      }
      links {
        label
        url
      }
      files {
        file
        label
      }
      companies
      profile
      offerings
    }
    image: file(sourceInstanceName: { eq: "images" }, name: { eq: $image }) {
      childImageSharp {
        fixed(height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
