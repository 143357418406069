import React from 'react';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import NotFound from '../svgs/not-found.svg';

function NotFoundPage() {
  return (
    <>
      <SEO title="404: Not found" />
      <div className="bg-blue-200">
        <div className="flex flex-col items-center justify-start h-full h-screen max-w-screen-md mx-auto py-30">
          <h2 className="text-2xl font-bold text-gray-900 capitalize">Page not found</h2>
          <p className="text-gray-700 ">
            Click{' '}
            <Link to="/" className="underline">
              here
            </Link>{' '}
            to go to the home page.
          </p>
          <div className="relative w-4/5 pb-5/12">
            <NotFound className="absolute inset-0 w-full h-full" />
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
