import React from 'react';
import SEO from '../components/seo';

function hankyou() {
  return (
    <>
      <SEO title="Thank You" />
      <div className="px-4 py-20 bg-gray-200 h-720px md:h-screen-80 md:flex md:items-center">
        <div className="max-w-screen-sm p-8 mx-auto text-gray-800 bg-white sm:rounded sm:shadow-lg">
          <h2 className="text-4xl font-black">Thank you</h2>
          <p>Thank you for your query, we will get in touch with you shortly.</p>
        </div>
      </div>
    </>
  );
}

export default hankyou;
