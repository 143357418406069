import React from 'react';
import useScrollTo from '../hooks/useScrollTo';
import useScrollButtonState from '../hooks/useScrollButtonState';

function ScrollTop() {
  const scroll = useScrollTo();
  const { visible } = useScrollButtonState();

  if (visible) {
    return (
      <div className="fixed bottom-0 right-0 flex justify-end mb-4 mr-4">
        <button
          className="p-2 text-xs text-gray-500 uppercase bg-gray-700 rounded"
          onClick={scroll}
          aria-label="scroll to the top">
          <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
              clipRule="evenodd"
              fillRule="evenodd"></path>
          </svg>
        </button>
      </div>
    );
  }

  return null;
}

export default ScrollTop;
