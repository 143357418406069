import PropTypes from 'prop-types';
import React from 'react';
import Icon from './icon';

function ServiceCard({ iconName, title, description }) {
  return (
    <div className="sm:px-4 md:py-8 md:w-1/2 md:flex-grow xl:w-1/3">
      <div className="relative h-full px-6 py-12 mb-16 bg-white sm:rounded-md sm:shadow-lg md:flex md:flex-col lg:items-center lg:py-0 lg:pt-16 lg:pb-10">
        <div className="absolute top-0 w-20 h-20 p-2 -mt-10 bg-blue-100 rounded-full lg:-mt-12 lg:w-24 lg:h-24">
          <div className="overflow-hidden rounded-full bg-lime-green">
            <Icon name={iconName} className="w-full h-full text-suite-blue" />
          </div>
        </div>
        <h3 className="text-xl font-bold capitalize">{title}</h3>
        <p className="mt-2 text-gray-700">{description}</p>
      </div>
    </div>
  );
}

ServiceCard.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ServiceCard;
